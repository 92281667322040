const myHttp = {
  install(Vue) {

    Vue.prototype.$info = function(message)
    {
      this.$message({
        type: 'info',
        message:message,
        offset: 280
      });
    };
    Vue.prototype.$success = function(message)
    {
      this.$message({
        type: 'success',
        message:message,
        offset: 280
      });
    };
    Vue.prototype.$error = function(message)
    {
      this.$message({
        type: 'error',
        message:message,
        offset: 280
      });
    };

    Vue.prototype.postDataFile = function (api,params,success){
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      let formData = wkUtils.objToFormData(params);
      this.$axios.post(api, formData,config).then(response => {
        let apiResult = response.data;
        if (apiResult && apiResult.code === 0) {
          let handled = success(apiResult)

          if(!handled)
          {
            let message = apiResult.message?apiResult.message:'成功';
            this.$message({
              type: 'success',
              message:message
            });
          }

        } else {
          let message;
          if(apiResult && apiResult.message)
          {
            message = apiResult.message;
          }
          else {
            message = '失败';
          }
          this.$message({
            type: 'error',
            message: message
          });
        }
      }).catch(err => {
        this.$message.error(err)
      })
    };

    Vue.prototype.postData = function (api,params,success){
      this.$axios.post(api, qs.stringify(params)).then(response => {
        let apiResult = response.data;
        if (apiResult && apiResult.code === 0) {
          let handled = success(apiResult)

          if(!handled)
          {
            let message = apiResult.message?apiResult.message:'成功';
            this.$message({
              type: 'success',
              message:message
            });
          }

        } else {
          let message;
          if(apiResult && apiResult.message)
          {
            message = apiResult.message;
          }
          else {
            message = '失败';
          }
          this.$message({
            type: 'error',
            message: message
          });
        }
      }).catch(err => {
        this.$message.error(err)
      })
    };
    Vue.prototype.postJson = function (api,params,success){
      this.$axios.post(api, params).then(response => {
        let apiResult = response.data;
        if (apiResult && apiResult.code === 0) {
          let handled = success(apiResult)

          if(!handled)
          {
            let message = apiResult.message || '成功';
            this.$message({
              type: 'success',
              message:message
            });
          }

        } else {
          let message;
          if(apiResult && apiResult.message)
          {
            message = apiResult.message;
          }
          else {
            message = '失败';
          }
          this.$message({
            type: 'error',
            message: message
          });
        }
      }).catch(err => {
        this.$message.error(err)
      })
    };
    Vue.prototype.getData = function (api,params,success){
      return this.$axios.get(api, {params: params}).then(response => {
        let apiResult = response.data;
        if (apiResult && apiResult.code === 1) {
          let handled = success(apiResult)

          if(!handled)
          {
            let message = apiResult.message?apiResult.message:'成功';
            this.$message({
              type: 'success',
              message:message
            });
          }

        } else {
          let message;
          if(apiResult && apiResult.message)
          {
            message = apiResult.message;
          }
          else {
            message = '失败';
          }
          this.$message({
            type: 'error',
            message: message
          });
        }
      }).catch(err => {
        console.log(err)
        this.$message.error(err)
      })
    };
    // Vue.prototype.handleAllAxios = function (...funs) {
    //     console.log(funs)
    //
    //     let arr = []
    //     return this.$axios.all(funs)
    //         .then(this.$axios.spread((...result) => {
    //
    //         }))
    // }
  }
}

export default myHttp
