// import Vue from 'vue'
// /*格式化字符串*/
// Vue.filter('formatString', function (value, start, num) {
//   alert(123)
//   if (!value) return ''
//   if (num) {
//     value = value.toString().substr(start, num)
//   } else {
//     value = value.toString().substr(start)
//   }
//   return value
// })
// /*千分位格式化*/
// Vue.filter('formatMoney', function (value,symbol) {
//   const reg=/\d{1,3}(?=(\d{3})+$)/g;
//   const str = symbol || ''
//   return `${str} ${value.toFixed(2).replace(reg, '$&,')}`;
// })
/*保留几位小数*/
// Vue.filter('formatMoney', function (value, symbol) {
//   const reg=/\d{1,3}(?=(\d{3})+$)/g;
//   const str = symbol || ''
//   return `${str} ${value.toFixed(2).replace(reg, '$&,')}`;
// })
exports.encryPhone = (phone) => {
  return String(phone).replace(/^(\d{3})\d{3}(\d+)/, "$1***$2");
}
exports.formatString = (value, start, num)=> {
  if (!value) return ''
  if (num) {
    value = value.toString().substr(start, num)
  } else {
    value = value.toString().substr(start)
  }
  return value
}
exports.subString = (value, number)=> {
  let str = value || '-';
  let output;

  str.length > number ? (output = `${str.substring(0, number)}...`) : (output = str);
  return output;
}
exports.formatMoney = (value,symbol = '', number = 2) => {
  const reg=/\d{1,3}(?=(\d{3})+$)/g;
  return `${symbol} ${new Number(value).toFixed(number).replace(reg, '$&,')}`;
}
exports.formatThousands = (value) => {
  const reg=/\d{1,3}(?=(\d{3})+$)/g;
  return `${String(value).replace(reg, '$&,')}`;
}
exports.addZero = (num) => {
  if(parseInt(num) < 10){
    num = '0'+num;
  }
  return num;

};
exports.formatTimestamp = (time='', num = 0, format = 'yyyy-mm-dd') => {

  if(!time)
  {
    return '-';
  }

  const date = new Date(time);
  let target_seconds = date.getTime() + 1000*60*60*24*num;

  date.setTime(target_seconds);

  let month = date.getMonth() + 1;
  let strDate = date.getDate();

  if(format.toLowerCase() === 'yyyy-mm'){
    return `${date.getFullYear()}-${exports.addZero(month)}`
  } else if (format.toLowerCase() === 'yyyy-mm-dd hh:mm:ss'){
    return `${date.getFullYear()}-${exports.addZero(month)}-${exports.addZero(strDate)} ${exports.addZero(date.getHours())}:${exports.addZero(date.getMinutes())}:${exports.addZero(date.getSeconds())}`
  } else {
    return `${date.getFullYear()}-${exports.addZero(month)}-${exports.addZero(strDate)}`
  }
};
