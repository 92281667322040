<template>
    <div class="page_header">
      <slot name="left"></slot>

      <slot name="right"></slot>
    </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    // title: String
  }
}
</script>

<style lang="scss" scoped>
.page_header{
  width: 100%;
  height: 40px;
  background: #fff;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  .title{
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 3px;
    color: #3D3D3D;
  }
  .right{
    cursor: pointer;
  }
}
</style>
