import CryptoJS from 'crypto-js'
export default class WkUtils {
  static isObjectValueEquali(a, b) {
    // Object.getOwnPropertyNames()方法返回一个由指定对象的所有自身属性的属性名（包括不可枚举属性但不包括Symbol值作为名称的属性）组成的数组
    // 换句话来说 Object.getOwnPropertyNames()方法返回的是对象所有 key 组成的数组 list
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);
    if (aProps.length != bProps.length) {
      return false;
    }

    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];
      if (a[propName] !== b[propName]) {
        return false;
      }
    }
    return true;
  }

  static objToFormData(config) {
    let formData = new FormData();
    let obj = config;
    Object.keys(obj).map((key) => {

      formData.append(key, obj[key]);

    })
    return formData;
  }
  // static formatDateToDay(ms) {
  //   // console.log("date ms = " + ms);
  //   let oDate = new Date(ms);
  //   let oYear = oDate.getFullYear()
  //   let oMonth = oDate.getMonth() + 1
  //   let oDay = oDate.getDate()
  //   let dateStr = oYear +  this.addZero(oMonth) +  this.addZero(oDay);
  //   // console.log("datestr = " + dateStr);
  //   return dateStr;
  // }
  /**
   * 加密所需的时间格式
   *
   */
  static getNowDate() {
    const date = new Date();

    let yy = date.getFullYear().toString().substr(2, 2);
    let month = date.getMonth() + 1;
    let day = date.getDate();


    return `${yy}${this.addZero(month)}${this.addZero(day)}`
  };
  /**
  * AES ECB模式加密
  *
  */
  static encryptByAES(message, key) {
    // utf8字符串—>WordArray对象，WordArray是一个保存32位整数的数组，相当于转成了二进制
    if (!message) return '';
    const keyHex = CryptoJS.enc.Utf8.parse(key); //
    const messageHex = CryptoJS.enc.Utf8.parse(message);
    return CryptoJS.AES.encrypt(messageHex, keyHex, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
  }

  /**
   * 生成加密key
   *
   */
  static buildKey(val) {
    const day = this.getNowDate()
    console.log(day)
    if (val) {
      return `${day}&cl!${val}0`
    } else {
      return `${day}&cl!${day}`
    }
  }
  /**
   * 转16进制
   *
   */
  static stringToHex(str){
    let val= [];
    for(var i = 0; i < str.length; i++){
      val.push((str.charCodeAt(i)).toString(16))
    }
    return val.join('');
  }


  /**
   * 字符串保留多少位
   * @param {String} str - 裁剪的字符串
   * @param {String} number - 保留的位数，如10
   * @returns 裁剪后的结果
   */
  static subStr(str, number) {
    let input = str || '';

    return input.length > number ? (`${input.substring(0, number)}...`) : (input);
  };
  /**
   * 格式化当前时间前后几天的数据
   * @param {String} day - 获取当前时间或者当前时间前后的时间，days为正表示后几天，为负表示前几天
   * @returns 格式化后的时间
   */
  static getDate(day = 0) {
    const date = new Date();

    let target_seconds = date.getTime() + 1000*60*60*24*day;

    date.setTime(target_seconds);

    let month = date.getMonth() + 1;
    let strDate = date.getDate();

    return `${date.getFullYear()}-${this.addZero(month)}-${this.addZero(strDate)} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()-1}`;
  };

  /**
   * 复制对象
   * @param {obj} obj - 复制的对象
   * @returns {obj}
   */
  static clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  static isObjectValueEquali(a, b) {
    // Object.getOwnPropertyNames()方法返回一个由指定对象的所有自身属性的属性名（包括不可枚举属性但不包括Symbol值作为名称的属性）组成的数组
    // 换句话来说 Object.getOwnPropertyNames()方法返回的是对象所有 key 组成的数组 list
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);
    if (aProps.length != bProps.length) {
      return false;
    }

    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];
      if (a[propName] !== b[propName]) {
        return false;
      }
    }
    return true;
  }
  /**
   * 设置缓存
   * @param {String} name - 缓存的key值
   * @param {String} value - 缓存的value值
   * @param {String} expire - 缓存的过期时间，可为空
   */
  static dbSet(name, value, expire) {
    let obj = {
      data: value,
      time: Date.now(),
      expire: expire
    };
    localStorage.setItem(name, JSON.stringify(obj));
  }
  /**
   * 获取缓存
   * @param {String} key - 缓存的key值
   * @returns {String}
   */
  static dbGet(key) {
    let val = localStorage.getItem(key) || '{}';

    if (!val) {
      return val;
    }
    val = JSON.parse(val);
    if (Date.now() - val.time > val.expire) {
      localStorage.removeItem(key);
      return null;
    }

    return val.data;
  }

  static dbClear()
  {
    localStorage.clear()
  }
  static dbDel(name)
  {
    localStorage.removeItem(name)
  }
  static addZero(num) {
    if(parseInt(num) < 10){
      num = '0'+num;
    }
    return num;
  }

  // static formatDate(ms) {
  //   let oDate = new Date(ms);
  //   let oYear = oDate.getFullYear()
  //   let oMonth = oDate.getMonth() + 1
  //   let oDay = oDate.getDate()
  //   let oHour = oDate.getHours()
  //   let oMin = oDate.getMinutes()
  //   let oSen = oDate.getSeconds()
  //   let dateStr = oYear + '-' + this.addZero(oMonth) + '-' + this.addZero(oDay) + ' ' + this.addZero(oHour) + ':' +
  //     this.addZero(oMin) + ':' + this.addZero(oSen);
  //   return dateStr;
  //
  // }
  static getTargetDate (time=new Date(), num = 0, format = 'yyyy-mm-dd') {
    const date = new Date(time);
    let target_seconds = date.getTime() + 1000*60*60*24*num;

    date.setTime(target_seconds);

    let month = date.getMonth() + 1;
    let strDate = date.getDate();
    if(format.toLowerCase() === 'yyyy-mm'){
      return `${date.getFullYear()}-${this.addZero(month)}`
    } else if (format.toLowerCase() === 'yyyy-mm-dd hh-mm-ss'){
      return `${date.getFullYear()}-${this.addZero(month)}-${this.addZero(strDate)} ${this.addZero(date.getHours())}:${this.addZero(date.getMinutes())}:${this.addZero(date.getSeconds())}`
    } else if (format.toLowerCase() === 'yyyy-mm-dd hh'){
      return `${date.getFullYear()}-${this.addZero(month)}-${this.addZero(strDate)} ${this.addZero(date.getHours())}`
    } else if (format.toLowerCase() === 'hh-mm-ss'){
      return `${this.addZero(date.getHours())}:${this.addZero(date.getMinutes())}:${this.addZero(date.getSeconds())}`
    } else {
      return `${date.getFullYear()}-${this.addZero(month)}-${this.addZero(strDate)}`
    }
  };


}








