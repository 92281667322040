import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'


import user from './modules/user'

import getters from './getters'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 认证状态参数modules
    // getters.js
    rootData: {},
    // 未读消息数量
    noReadCount: 0,
    // 云信小号tab状态参数
    // privacyActiveTab: true,
    // 空号清洗tab参数
    // easyCleanTab: true,
    userList: []
  },
  mutations: {
    updateRootData (state, data){
      // console.log(data)
      return state.rootData = data
    },
    // updateUserList(state, data){
    //   // console.log(data)
    //   return state.userList = data
    // },
    // updateActiveTab (state, status){
    //   return state.privacyActiveTab = status
    // },
    // updateEasyCleanTab (state, status){
    //   return state.easyCleanTab = status
    // },
    updateNoReadCount(state, data){
      return state.noReadCount = data
    },
  },
  actions: {
    // GetUserList({ commit, param }) {
    //   // TODO 需要进一步优化
    //   return new Promise((resolve, reject) => {
    //     axios.get(`/api/admin/package/getUserLoginAccountCompany`)
    //       .then((res) => {
    //         // console.log(res)
    //         commit('updateUserList', res.data.data) //调用mutations下面的updateUserList方法并且传值过去
    //         resolve(res)
    //       }).catch(error => {
    //       reject(error)
    //     })
    //
    //   })
    // },
    // 用户是否认证
    GetUserVerified({ commit, param = {} }) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/user/getUserVerified`)
          .then((res) => {
            console.log(res)
            // commit('updateUserList', res.data.data) //调用mutations下面的updateUserList方法并且传值过去
            resolve(res)
          }).catch(error => {
          reject(error)
        })

      })
    }
  },
  modules: {
    user
  },
  getters
})
