import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
// 引入自定义过滤器文件
import filters from './filter'
Object.keys(filters).forEach(k => Vue.filter(k, filters[k]));
// 引入复制功能文件
import VueClipboard from 'vue-clipboard2'
// 引入测试自定义js文件
import './assets/test.js'
// 引入正则判断条件
import wkUtils from "./utils/wkUtils";

// 引入elementui相关文件
import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/element-variables.scss'
// import './assets/theme/index.css'
// 引入自定义样式文件
import './assets/css/iconfont.css'
import './assets/css/rewrite.scss'
import './assets/css/base.scss'
import './assets/css/index.scss'

import httpUtils from './utils/httpUtils'
Vue.use(httpUtils);

import {Loading, Message, Confirm, Prompt} from 'element-ui'
Vue.prototype.msgSuccess = function(msg, config = {}) {
  this.$message({
    type: "success",
    showClose: config.showClose || false,
    message: msg,
    duration: config.duration || 3000,
  });
}

Vue.prototype.msgError = function(msg, config = {}) {
  this.$message({
    type: "error",
    showClose: config.showClose || false,
    message: msg,
    duration: config.duration || 3000,
  });
}
Vue.prototype.msgWarning = function(msg, config = {}) {
  this.$message({
    type: "warning",
    showClose: config.showClose || false,
    message: msg,
    duration: config.duration || 3000,
  });
}
Vue.prototype.msgInfo = function(msg, config = {}) {
  this.$message({
    showClose: config.showClose || false,
    message: msg,
    duration: config.duration || 3000,
  });
}
Vue.prototype.confirmBox = function(msg, title = ' 温馨提示', config ={}) {
  return this.$confirm(msg, title, {
    dangerouslyUseHTMLString: true,
    closeOnClickModal: false,
    confirmButtonText: config.confirmBtnText || '确定',
    cancelButtonText: config.cancelBtnText || '取消',
    type: config.type || 'warning'
  })
}
// 注释遮罩层 start
// axios.defaults.headers = {
//   showLoading: true
// }
// 定义一个loading实例
// var loadingInstance;

// 注释遮罩层 start

// 请求拦截
axios.interceptors.request.use((config) => {
  config.headers['usersource'] = '1'
  // console.log(config)
  // 注释遮罩层 start
  // if (config.headers.showLoading) {
  //   // 在发送请求之前处理，比如加入请求头，做统一的loading管理
  //   loadingInstance = Loading.service({
  //     lock: true,
  //     text: '正在加载。。。',
  //     spinner: 'el-icon-loading',
  //     background: 'rgba(0, 0, 0, 0.7)'
  //   })
  // }
  // 注释遮罩层 start

  return config;
}, (error) => {
  // 对请求错误处理
  // console.log('请求错误', error)
  // closeLoading()
  Vue.prototype.msgError(`请求超时!${error}` || '系统错误', {})
  return Promise.reject(error);
})
// 响应拦截
axios.interceptors.response.use((response) => {
  // 在接收响应做些什么，例如跳转到登录页
  // console.log(response)


  if(response && response.data.code === 302)
  {
    clearInterval(wkUtils.dbGet('timer'))
    wkUtils.dbClear()
    location.href = store.state.user.redirect;
  }
  // closeLoading()
  return response;
}, (error) => {
  // 对响应错误做处理
  // console.log(err)
  // closeLoading()

  if(!error.response){
    clearInterval(wkUtils.dbGet('timer'))
    wkUtils.dbClear()
    location.href = store.state.user.redirect;
    return Promise.reject(error);
  }else{
    if (error.response.status == 302) {
      clearInterval(wkUtils.dbGet('timer'))
      wkUtils.dbClear()
      location.href = store.state.user.redirect;
      return Promise.reject(error);
    }else if (error.response.status == 404) {
      Vue.prototype.msgError('接口地址错误' || '404', {});
    } else {
      Vue.prototype.msgError('未知错误,请联系管理员!', {});
    }
    // 返回Promise.resolve，请求不会就此结束，接着会执行成功的回调函数
  }

  return Promise.resolve(error.response);

});
// 注释遮罩层 start
// function closeLoading() {
//   if (loadingInstance) {
//     loadingInstance.close()
//   }
// }
// 注释遮罩层 end


Vue.prototype.$axios = axios
Vue.prototype.$Message = Message
Vue.prototype.$confirm = Confirm
Vue.prototype.$prompt = Prompt

// Vue.prototype.$Validate = Validate

Vue.config.productionTip = false
// Vue.config.devtools = true

Vue.use(ElementUI)
Vue.use(VueClipboard)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
