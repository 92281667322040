

const user = {
  state: {
    // 0,普通用户；1，代理商
    proxyFlag: '',
    // 重定向页面地址，代理商退出到/login_ctc页面，其他到login页面
    redirect: '/login',
    // 充值账户信息
    bankInfo: {},
    // 代理商信息
    proxyInfo: {
      tel400: '',
      wechatNo: ''
    }

  },

  mutations: {
    SET_PROXYFLAG: (state, proxyFlag) => {
      console.log(proxyFlag)
      state.proxyFlag = proxyFlag
    },
    SET_REDIRECT: (state, redirect) => {
      state.redirect = redirect
    },
    SET_BANKINFO: (state, bankInfo) => {
      state.bankInfo = bankInfo
    },
    SET_PROXYINFO: (state, proxyInfo) => {
      state.proxyInfo = {
        tel400: proxyInfo.tel400,
        wechatNo: proxyInfo.wechatNo,
      }
    },
  },

  actions: {
    updateProxyFlag({ commit }, { proxyFlag, bank, branchBank, bankAccountName, bankAccount, tel400, wechatNo}) {

      commit('SET_PROXYFLAG', proxyFlag)
      commit('SET_REDIRECT', proxyFlag == 1 ? '/login_ctc' : '/login')
      commit('SET_BANKINFO', proxyFlag == 1 ? {
        bank, branchBank, bankAccountName, bankAccount
      } : {})
      commit('SET_PROXYINFO', proxyFlag == 1 ? {
        tel400, wechatNo
      } : {})

    }
    // // 用户密码重置
    // RemoteHelpLogin({ commit }, {toUserName, verifyCode}) {
    //   return new Promise( (resolve,reject) => {
    //      remoteHelpLogin(toUserName,verifyCode).then( res => {
    //         if(res.token)
    //         {
    //           setToken(res.token)
    //           commit('SET_TOKEN', res.token)
    //         }
    //         resolve(res)
    //      }).catch( error => {
    //        reject(error)
    //      })
    //   })
    // },
    // // 登录
    // Login({ commit }, userInfo) {
    //   const username = userInfo.username.trim()
    //   const password = userInfo.password
    //   const code = userInfo.code
    //   const uuid = userInfo.uuid
    //   const cookieId = userInfo.cookieId
    //   return new Promise((resolve, reject) => {
    //     login(username, password, code, uuid, cookieId).then(res => {
    //       let { verified } = res;
    //       commit('SET_VERIFYTOKEN', res.verifyToken);
    //       commit('SET_VERIFIED', verified);
    //       if (verified === 2) {
    //         commit('SET_PHONE', res.phone)
    //       } else if (verified === 1) {
    //         setToken(res.token)
    //         commit('SET_TOKEN', res.token)
    //         commit('SET_USER_TYPE',res.userType)
    //         if(res.messageTopic)
    //         {
    //           commit('SET_MESSAGE_TOPIC',res.messageTopic)
    //         }
    //       };
    //       resolve(res)
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },

    // // 获取用户信息
    // GetInfo({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     getInfo(state.token).then(res => {
    //       const user = res.user
    //       const avatar = user.avatar == "" ? require("@/assets/image/profile.jpg") : process.env.VUE_APP_BASE_API + user.avatar;
    //       if (res.roles && res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
    //         commit('SET_ROLES', res.roles)
    //         commit('SET_PERMISSIONS', res.permissions)
    //       } else {
    //         commit('SET_ROLES', ['ROLE_DEFAULT'])
    //       }
    //       commit('SET_NAME', user.userName)
    //       commit('SET_NICKNAME', user.nickName)
    //       commit('SET_AVATAR', avatar)
    //       commit('SET_USER_TYPE',user.userType)
    //       if(res.messageTopic)
    //       {
    //          commit('SET_MESSAGE_TOPIC',res.messageTopic)
    //       }
    //
    //       resolve(res)
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },

    // // 退出系统
    // LogOut({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     logout(state.token).then(() => {
    //       commit('SET_TOKEN', '')
    //       commit('SET_ROLES', [])
    //       commit('SET_PERMISSIONS', [])
    //       removeToken()
    //       resolve()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
    //
    // // 前端 登出
    // FedLogOut({ commit }) {
    //   return new Promise(resolve => {
    //     commit('SET_TOKEN', '')
    //     removeToken()
    //     resolve()
    //   })
    // }
  }
}

export default user
