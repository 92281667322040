import Vue from 'vue'
import VueRouter from 'vue-router'
import WkUtils from "@/utils/wkUtils";
import store from '../store'

/* TODO 解决相同路由点击报错问题*/
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// 引入一级页面

const NotFound = () => import(/* webpackChunkName: "404" */'../views/404.vue')

const Login = () => import(/* webpackChunkName: "login" */'../views/login.vue')
const LoginCtc = () => import(/* webpackChunkName: "LoginCtc" */'../views/loginCtc.vue')

const Register = () => import(/*webpackChunkName: 'register'*/'../views/register.vue')
const RegisterScrm = () => import(/*webpackChunkName: 'regist'*/'../views/registerScrm.vue')
const ResetPw = () => import(/*webpackChunkName: 'register'*/'../views/resetPw.vue')
const Index = () => import(/*webpackChunkName: 'index'*/'../views/index.vue')
const Unauthorized = () => import(/*webpackChunkName: 'index'*/'../views/unauthorized.vue')
// 引入二级页面
const HomePage = () => import(/*webpackChunkName: 'home'*/'../views/client/home/HomePage.vue')
// 我的应用

const App = () => import(/* webpackChunkName: "App" */'../views/client/app/App.vue')
const AppDetail = () => import(/* webpackChunkName: "App" */'../views/client/app/AppDetail.vue')


const PrivacyPage = () => import(/* webpackChunkName: "privacy" */'@/views/client/ability/privacy/PrivacyPage.vue')

const MyPrivacy = () => import(/* webpackChunkName: "privacy" */'@/views/client/ability/privacy/MyPrivacy.vue')
const PrivacyDetail = () => import(/* webpackChunkName: "PrivacyDetail" */'@/views/client/ability/privacy/PrivacyDetail.vue')
const PrivacyHistory = () => import(/* webpackChunkName: "PrivacyHistory" */'@/views/client/ability/privacy/PrivacyHistory.vue')
const OnlineTest = () => import(/* webpackChunkName: "OnlineTest" */'@/views/client/ability/privacy/OnlineTest.vue')
const PrivacySmsRecord = () => import(/* webpackChunkName: "PrivacySmsRecord" */'@/views/client/ability/privacy/PrivacySmsRecord.vue')
const PackageDetail = () => import(/* webpackChunkName: "PackageDetail" */'@/views/client/ability/privacy/PackageDetail.vue')

const CallRecord = () => import(/* webpackChunkName: "CallRecord" */'@/views/client/ability/privacy/CallRecord.vue')
const PrivacyApi = () => import(/* webpackChunkName: "PrivacyApi" */'@/views/client/ability/privacy/PrivacyApi.vue')
const BuyNumber = () => import(/* webpackChunkName: "BuyNumber" */'@/views/client/ability/privacy/BuyNumber.vue')
const BuyBag = () => import(/* webpackChunkName: "BuyBag" */'@/views/client/ability/privacy/BuyBag.vue')

const EasyClean = () => import(/* webpackChunkName: "easyClean" */'@/views/client/ability/easyClean/EasyClean.vue')
const EasyRecord = () => import(/* webpackChunkName: "easyClean" */'@/views/client/ability/easyClean/EasyRecord.vue')
const EasyDetail = () => import(/* webpackChunkName: "easyClean" */'@/views/client/ability/easyClean/EasyDetail.vue')
const EasyApi = () => import(/* webpackChunkName: "easyClean" */'@/views/client/ability/easyClean/EasyApi.vue')
const BuyPackage = () => import(/* webpackChunkName: "easyClean" */'@/views/client/ability/easyClean/BuyPackage.vue')

const PreciseClean = () => import(/* webpackChunkName: "preciseClean" */'@/views/client/ability/preciseClean/PreciseClean.vue')
const CleanRecord = () => import(/* webpackChunkName: "preciseClean" */'@/views/client/ability/preciseClean/CleanRecord.vue')
const CleanDetail = () => import(/* webpackChunkName: "preciseClean" */'@/views/client/ability/preciseClean/CleanDetail.vue')
const CleanApi = () => import(/* webpackChunkName: "preciseClean" */'@/views/client/ability/preciseClean/CleanApi.vue')
const Buy = () => import(/* webpackChunkName: "preciseClean" */'@/views/client/ability/preciseClean/Buy.vue')

const OperatorClean = () => import(/* webpackChunkName: "operatorClean" */'@/views/client/ability/operatorClean/OperatorClean.vue')
const OperatorCleanRecord = () => import(/* webpackChunkName: "operatorClean" */'@/views/client/ability/operatorClean/CleanRecord.vue')
const OperatorCleanDetail = () => import(/* webpackChunkName: "operatorClean" */'@/views/client/ability/operatorClean/CleanDetail.vue')
const OperatorCleanApi = () => import(/* webpackChunkName: "operatorClean" */'@/views/client/ability/operatorClean/CleanApi.vue')
const OperatorBuy = () => import(/* webpackChunkName: "operatorClean" */'@/views/client/ability/operatorClean/Buy.vue')

const Sms = () => import(/* webpackChunkName: "sms" */'@/views/client/ability/sms/sms.vue')

const SmsPage = () => import(/* webpackChunkName: "sms" */'@/views/client/ability/sms/SmsPage.vue')
const SmsSend = () => import(/* webpackChunkName: "sms" */'@/views/client/ability/sms/SmsSend.vue')
const SmsDetail = () => import(/* webpackChunkName: "sms" */'@/views/client/ability/sms/SmsDetail.vue')
const SmsSig = () => import(/* webpackChunkName: "sms" */'@/views/client/ability/sms/SmsSig.vue')
const SmsStatistics = () => import(/* webpackChunkName: "sms" */'@/views/client/ability/sms/SmsStatistics.vue')
const SmsTemplate = () => import(/* webpackChunkName: "sms" */'@/views/client/ability/sms/SmsTemplate.vue')
const SmsBuy = () => import(/* webpackChunkName: "sms" */'@/views/client/ability/sms/SmsBuy.vue')
const SmsCreateSig = () => import(/* webpackChunkName: "sms" */'@/views/client/ability/sms/SmsCreateSig.vue')
const SmsCreateTemplate = () => import(/* webpackChunkName: "sms" */'@/views/client/ability/sms/SmsCreateTemplate.vue')
const SmsApis = () => import(/* webpackChunkName: "sms" */'@/views/client/ability/sms/SmsApi.vue')

// 消费
const ConsumeDetail = () => import(/* webpackChunkName: "consume" */'../views/client/consume/detail.vue')
const ConsumeStatistics = () => import(/* webpackChunkName: "consume" */'../views/client/consume/ConsumeStatistics.vue')
const MonthlyBill = () => import(/* webpackChunkName: "consume" */'@/views/client/consume/MonthlyBill.vue')

// 充值
const OnlineRecharge = () => import(/* webpackChunkName: "recharge" */'../views/client/recharge/OnlineRecharge.vue')
const RechargeRecord = () => import(/* webpackChunkName: "recharge" */'../views/client/recharge/RechargeRecord.vue')
// 用户

const UserPage = () => import(/* webpackChunkName: "userPage" */'../views/client/userPage/UserPage.vue')
const UserReset = () => import(/* webpackChunkName: "userPage" */'../views/client/userPage/UserReset.vue')
const Config = () => import(/* webpackChunkName: "userPage" */'../views/client/userPage/Config.vue')
const UpdateTel = () => import(/* webpackChunkName: "userPage" */'../views/client/userPage/UpdateTel.vue')

// api文档
const ApiDocument = () => import(/* webpackChunkName: "ApiDocument" */'../views/client/api/ApiDocument.vue')
// 消息中心
const MsgCenter  = () => import(/* webpackChunkName: "msgCenter" */'../views/client/msg/msgCenter.vue')
const MsgDetail = () => import(/* webpackChunkName: "msgCenter" */'../views/client/msg/msgDetail.vue')

const scrmHome = () => import('../views/client/ability/scrm/scrmHome.vue')

// 全局组件
import PageHeader from '@/components/common/PageHeader'
import PagePagination from '@/components/common/PagePagination'
import wkUtils from '../utils/wkUtils'

Vue.use(VueRouter)
Vue.component('page-header', PageHeader)
Vue.component('page-pagination', PagePagination)

const routes = [
  {
    path: '*',
    name: 'notFound',
    component: NotFound
  },
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/login_ctc',
    name: 'LoginCtc',
    component: LoginCtc
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/registerScrm',
    name: 'RegisterScrm',
    component: RegisterScrm
  },
  {
    path: '/register/:id',
    name: 'register',
    component: Register
  },
  {
    path: '/resetPw',
    name: 'resetPw',
    component: ResetPw
  },
  {
    path: '/index',
    name: 'Index',
    component: Index,
    redirect: '/home',
    children: [
      // 用户端
      {path: '/home', name: 'HomePage', component: HomePage, meta: {  }},
      {path: '/unauthorized', name: 'unauthorized', component: Unauthorized},
      {path: '/app', name: 'App', component: App, meta: { role: 'user' }},
      {path: '/appDetail/:id', name: 'AppDetail', component: AppDetail, meta: { role: 'user', prev: '/app' }},

      {path: '/consumeDetail', name: 'ConsumeDetail', component: ConsumeDetail, meta: { role: 'user' }},
      {path: '/consumeStatistics', name: 'ConsumeStatistics', component: ConsumeStatistics, meta: { role: 'user' }},
      {path: '/monthlyBill', name: 'MonthlyBill', component: MonthlyBill, meta: { role: 'user' }},
      {path: '/privacy', name: 'PrivacyPage', component: PrivacyPage, redirect: '/privacy/myPrivacy', meta: { role: 'user' },
        children: [

          {path: '/privacy/myPrivacy', name: 'MyPrivacy', component: MyPrivacy, meta: { role: 'user' }},
          {path: '/privacy/numberDetail/:number', name: 'PrivacyDetail', component: PrivacyDetail},
          // {path: '/privacy/detail', name: 'PrivacyDetail', component: PrivacyDetail},
          {path: '/privacy/history', name: 'PrivacyHistory', component: PrivacyHistory},

          {path: '/privacy/packageDetail', name: 'PackageDetail', component: PackageDetail},
          {path: '/privacy/record', name: 'CallRecord', component: CallRecord},

          {path: '/privacy/online', name: 'OnlineTest', component: OnlineTest},
          {path: '/privacy/privacySmsRecord', name: 'PrivacySmsRecord', component: PrivacySmsRecord},
          {path: '/privacy/document', name: 'PrivacyApi', component: PrivacyApi},
          {path: '/privacy/buyNumber', name: 'BuyNumber', component: BuyNumber},
          {path: '/privacy/buyBag', name: 'BuyBag', component: BuyBag},


        ]},


      {path: '/easyClean', name: 'EasyClean', component: EasyClean, redirect: '/easyClean/record', meta: { role: 'user' },
        children: [
          {path: '/easyClean/record', name: 'EasyRecord', component: EasyRecord, meta: { role: 'user' }},
          {path: '/easyClean/detail', name: 'EasyDetail', component: EasyDetail},
          {path: '/easyClean/document', name: 'EasyApi', component: EasyApi},
          {path: '/easyClean/buy', name: 'BuyPackage', component: BuyPackage},

        ]
      },
      {path: '/preciseClean', name: 'PreciseClean', component: PreciseClean, redirect: '/preciseClean/record',meta: { role: 'user' },
        children: [
          {path: '/preciseClean/record', name: 'CleanRecord', component: CleanRecord, meta: { role: 'user' }},
          {path: '/preciseClean/detail', name: 'CleanDetail', component: CleanDetail},
          {path: '/preciseClean/document', name: 'CleanApi', component: CleanApi},
          {path: '/preciseClean/buy', name: 'Buy', component: Buy},

        ]},
      {path: '/operatorClean', name: 'OperatorClean', component: OperatorClean, redirect: '/operatorClean/record',meta: { role: 'user' },
        children: [
          {path: '/operatorClean/record', name: 'OperatorCleanRecord', component: OperatorCleanRecord, meta: { role: 'user' }},
          {path: '/operatorClean/detail', name: 'OperatorCleanDetail', component: OperatorCleanDetail},
          {path: '/operatorClean/document', name: 'OperatorCleanApi', component: OperatorCleanApi},
          {path: '/operatorClean/buy', name: 'OperatorBuy', component: OperatorBuy},

        ]},
      // {path: '/sms', name: 'Sms', component: Sms, meta: { role: 'user' }},
      {path: '/sms', name: 'SmsPage', component: SmsPage, redirect: '/sms/send',meta: { role: 'user' },
        children: [
          {path: '/sms/send', name: 'SmsSend', component: SmsSend, meta: { role: 'user' }},
          {path: '/sms/detail', name: 'SmsDetail', component: SmsDetail, meta: { role: 'user' }},
          {path: '/sms/sig', name: 'SmsSig', component: SmsSig, meta: { role: 'user' }},
          {path: '/sms/statistics', name: 'SmsStatistics', component: SmsStatistics, meta: { role: 'user' }},
          {path: '/sms/template', name: 'SmsTemplate', component: SmsTemplate, meta: { role: 'user' }},

          {path: '/sms/document', name: 'SmsApis', component: SmsApis},
          {path: '/sms/buy', name: 'SmsBuy', component: SmsBuy},
          {path: '/sms/createSig', name: 'SmsCreateSig', component: SmsCreateSig, meta: { role: 'user', prev: '/sms/sig'}},
          {path: '/sms/createTemplate', name: 'SmsCreateTemplate', component: SmsCreateTemplate, meta: { role: 'user', prev: '/sms/template'}},
        ]},
      {path: '/onlineRecharge', name: 'OnlineRecharge', component: OnlineRecharge, meta: { role: 'user' }},

      {path: '/finance/rechargeRecord', name: 'RechargeRecord', component: RechargeRecord, meta: { role: 'user' }},
      {path: '/user/info', name: 'UserPage', component: UserPage, meta: { role: 'user' }},
      {path: '/scrm/scrmHome', name: 'scrmHome', component: scrmHome, meta: { role: 'user' }},
      {path: '/user/reset', name: 'UserReset', component: UserReset, meta: { role: 'user' }},
      {path: '/config', name: 'Config', component: Config, meta: { role: 'user' }},
      {path: '/updateTel', name: 'UpdateTel', component: UpdateTel, meta: { role: 'user', prev: '/user' }},

      {path: '/document', name: 'ApiDocument', component: ApiDocument, meta: { role: 'user' }},
      {path: '/msgCenter', name: 'MsgCenter', component: MsgCenter, meta: { role: 'user' }},
      {path: '/msgDetail', name: 'MsgDetail', component: MsgDetail, meta: { role: 'user', prev: '/msgCenter' }},
    ]
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
//
// 路由守卫
router.beforeEach((to, from, next) => {
  let role = WkUtils.dbGet('ROLE')
  try {
    if (WkUtils.dbGet('rootData') && WkUtils.dbGet('rootData').user) {
      role = WkUtils.dbGet('rootData').user.role
    }
    const { path } = to;
    // 百度统计埋点
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }

    if(to.path.includes('/register/')) {
      next()
    }

    else if (path === '/login' || path === '/login_ctc' || path === '/register' || path==='/registerScrm' || path === '/resetPw') {
      role ? next('/index') : next()
    } else {
      if (role){
        if (to.meta.role && !to.meta.role.split(',').includes(role)) {
          next('/unauthorized')
        } else {
          next()
        }
      } else {
        // let { query } = to;
        // if (query.redirect) {
        //   next(`/${query.redirect}`)
        // } else {
        //   next('/login')
        // }
        next({path: store.state.user.redirect})
      }
    }
  }
  catch(err) {
    next('/login')
    wkUtils.dbClear()

  }


});
export default router
