<template>
  <div id="app">
    <!--<router-view/>-->
    <router-view/>
  </div>
</template>

<style>

</style>
<script>
  export default {
    name: 'App',
    data(){
      return{
      }
    },

    methods: {
    }

  }
</script>
